.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #18191A;
  padding: 0 32px;
}

@media (min-width: 680px) {
  .container {
    font-size: 16px;
    padding-left: 64px;
    padding-right: 64px;
  }
}
