.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  background-color: #18191A;
  padding: 24px;
  position: absolute;
  right: 0;
  width: 100%;
}
