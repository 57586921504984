.menu {
  display: flex;
  gap: 16px;
}

.current {
  li {
    border-bottom: 2px solid black;
    color: $blue;
  }
}

li:active {
  color: white
}
