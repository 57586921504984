h2, h3 {
  margin: 48px 0px 16px;
}

pre {
  display: block;
  overflow: auto;
  padding: 15px;
  background-color: gray;
  border-radius: 4px;
}

code {
  font-family: monospace;
  background-color: gray;
  padding: 0px 3px;
  border-radius: 2px;
}

pre > code {
  padding: 0px;
}

blockquote {
  padding: 0 1em;
  border-left: 0.25em solid grey;
  margin: 24px 0 24px 16px;
  font-style: italic;
  color: grey;
}

blockquote > p {
  margin: auto;
}

ul {
  margin: 0px;
}

ul > li {
  line-height: 3;
}
