.text {
  line-height: 2.5rem;
}

.yellow {
  color: #dcff1c;
  text-decoration: none;
}

.red {
  color: #FF355E;
  text-decoration: none;
}

.blue {
  color: #0095FF;
  text-decoration: none;
}
