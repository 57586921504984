.content {
  display: flex;
  max-width: 640px;
  padding: 128px 0;
  flex-direction: column;
}

p {
  line-height: 3;
}
